import api from "@/api";
import Button from "@/components/Button";
import Pagination from "@/components/Pagination";
import { SysInstitution } from "@/models/home";
import { ButtonListRow, OptionsRow, PaginationRow, TableRow } from "@/models/type";
import { debounceClick, getFile, typeWatch } from "@/utils";
import { isEmail, isEnName } from "@/utils/validate";
import { ElMessageBox, ElNotification } from "element-plus";
import { defineComponent } from "vue";

/**
 * 合作机构
 *  @type {*} */
const SpaceInstitution = defineComponent({
  data() {
    const emailValidate = (rule, value, callback): void => {
      if(!value) {
        callback(new Error('邮箱不能为空'))
      } else if (!isEmail(value)) {
        callback(new Error('请检查邮箱格式是否正确'))
      } else {
        callback()
      }
    }

    const enNameValidate = (rule, value, callback): void => {
      if(!value) {
        callback(new Error('英文名称不能为空'))
      } else if (!isEnName(value)) {
        callback(new Error('名称只能是英文'))
      } else {
        callback()
      }
    }
    const typeList = [
      { name: '资助机构', value: 1 },
      { name: '主办机构', value: 2 },
      { name: '合作机构', value: 3 },
      { name: '支持媒体', value: 4 }
    ]
    return {
      tableData: [] as Array<any>,
      detailBool: false,
      title: '',
      type: '',
      total: 0,
      loading: false,
      buttonList: [
        { title: '添加', icon: 'el-icon-plus', click: 'institutionAdd' }
      ] as Array<ButtonListRow>,
      typeList: [
        { name: '资助机构', value: 1 },
        { name: '主办机构', value: 2 },
        { name: '合作机构', value: 3 },
        { name: '支持媒体', value: 4 }
      ] as Array<OptionsRow>,
      tableList: [
        { label: '序号', slot: {
          default: (scope: any): JSX.Element => <span>{ scope.$index + 1 }</span>
        } },
        { label: '机构名称', prop: 'title' },
        { label: '机构属性', slot: {
          default: scope => <span>{typeList.find(item => item.value === scope.row.typeId)?.name}</span>
        } },
        { label: '机构连接', prop: 'url' },
        { label: '操作', slot: {
          default: scope => <div>
            <span class="span-button-primary" onClick={ () => this.institutionEdit(scope.row) }>编辑</span>
            <span class="span-button-danger" onClick={ () => this.institutionDelete(scope.row.id) }>删除</span>
          </div>
        } }
      ] as Array<TableRow>,
      institutionForm: {
        introduce: '',
        icon: '',
        title: '',
        typeId: '',
        url: ''
      } as SysInstitution,
      formRules: {
        cnName: [
          { required: true, message: '请输入中文名称' }
        ],
        enName: [
          { required: true, validator: enNameValidate }
        ],
        email: [
          { required: true, validator: emailValidate }
        ]
      },
      url: '',
      srcList: [] as Array<string>
    }
  },
  watch: {
    type(val: string) {
      this.title = typeWatch(val)
    }
  },
  methods: {
    buttonClick(click: string): void {
      this[click]()
    },
    institutionAdd(): void {
      this.type = 'add'
      this.detailBool = true
    },
    institutionEdit(row: SysInstitution): void {
      this.institutionForm = Object.assign({}, row)
      this.type = 'edit'
      this.detailBool = true
    },
    institutionDelete(id: number): void {
      ElMessageBox.confirm('确定删除该机构？', '删除机构', {
        type: 'warning'
      }).then(() => {
        api.homeInstitutionDeleteInstitution([id]).then(() => {
          ElNotification({
            title: '成功',
            type: 'success',
            message: '删除机构成功'
          })
          this.getList()
        })
      }).catch(err => {
        return err
      })
    },
    getList(): void {
      const __pagination = this.$refs.pagination as any
      const { pageSize, pageNum } = __pagination.paginationObj as PaginationRow

      api.homeInstitutionGetInstitutionList({ pageSize, pageNum }).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
      })
    },
    detailDom(): JSX.Element {
      const slots: any = {
        footer: (): JSX.Element => (
          <span class="dialog-footer">
            <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose } >取 消</button>
            <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
          </span>
        )
      }

      return (
        <el-dialog
          title={ `${ this.title }机构` }
          v-model={ this.detailBool }
          before-close={ this.detailClose }
          width="30%"
          v-slots={ slots }
        >
          <el-form
            ref="form"
            model={ this.institutionForm }
            rules={ this.formRules }
            label-width="120px"
            class="el-form-tao"
          >
            <el-form-item label="名称" prop="title">
              <el-input v-model={ this.institutionForm.title } clearable placeholder="请输入机构名称" />
            </el-form-item>
            <el-form-item label="属性" prop="typeId">
              <el-select v-model={ this.institutionForm.typeId } clearable placeholder="请选择机构属性" style="width:100%">
                {this.typeList.map(item => <el-option label={item.name} value={item.value} />)}
              </el-select>
            </el-form-item>
            <el-form-item label="连接" prop="url">
              <el-input v-model={ this.institutionForm.url } clearable placeholder="请输入机构连接" />
            </el-form-item>
            <el-form-item label="简介" prop="introduce">
              <el-input type="textarea" v-model={ this.institutionForm.introduce } clearable placeholder="请输入机构简介" maxlength={ 25 } show-word-limit />
            </el-form-item>
            <el-form-item label="图片" prop="imgPath">
              <div>
                { this.loading
                  ? <span class="el-form-tao-btn" >上传中...</span>
                  : <span class="el-form-tao-btn" onClick={ this.uploadFile }>{ this.institutionForm.icon ? '重新上传' : '上传' }</span>}
                {this.institutionForm.icon && <span class="el-form-tao-view" onClick={this.someView} >预览</span>}
              </div>
            </el-form-item>
          </el-form>
        </el-dialog>
      )
    },

    uploadFile(): void {
      getFile(files => {
        if(!files.length) {
          return
        }
        const formData = new FormData()
        formData.append('file', files[0])
        this.loading = true
        api.commonOssUpload(formData).then(res => {
          this.institutionForm.icon = res.data
          ElNotification({
            title: '成功',
            type: 'success',
            message: `上传图片成功`
          })
          this.loading = false
        })
      }, 'INSTITUTION_LOGO', 'image/*')
    },

    someView(): void {
      const url = this.institutionForm.icon as string
      this.url = url
      this.srcList = [url]
      this.imgViewerShow()
    },

    submit(): void {
      const __form = this.$refs.form as any
      __form.validate(v => {
        if(v) {
          const form = Object.assign({}, this.institutionForm)
          this.type === 'add'
           ? this.submitAdd(form)
           : this.submitEdit(form)
        } else {
          return false
        }
      })
    },
    submitAdd(form: SysInstitution): void {
      delete form.id
      api.homeInstitutionAddInstitution(form).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '添加机构信息成功'
        })
        this.detailClose()
        this.getList()
      })
    },
    submitEdit(form: SysInstitution): void {
      api.homeInstitutionEditInstitution(form).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '编辑机构信息成功'
        })
        this.detailClose()
        this.getList()
      })
    },
    detailClose(): void {
      this.detailBool = false
      this.type = ''
      this.institutionForm = {
        introduce: '',
        icon: '',
        title: '',
        typeId: '',
        url: ''
      }
    },
    imgViewerDom(): JSX.Element {
      return <el-image
        ref="img"
        style="width: 0; height: 0; z-index: 54188;"
        src={ this.url }
        preview-src-list={ this.srcList }
      >
      </el-image>
    },
    imgViewerShow(): void {
      const __img =  this.$refs.img as any
      __img.showViewer = true
    }
  },
  render(){
    return (
      <div class="space-institution">
        <Button list={ this.buttonList } onClick={ this.buttonClick } />
        <el-table
          data={ this.tableData }
        >
          {this.tableList.map(item => (
            <el-table-column
              prop={ item.prop }
              label={ item.label }
              width={ item.width }
              align={ item.align || 'center' }
              v-slots={ item.slot }
            />
          ))}
        </el-table>
        <Pagination ref="pagination" total={ this.total } />
        {this.imgViewerDom()}
        {this.detailBool && this.detailDom()}
      </div>
    )
  },
  mounted() {
    this.getList()
  }
})

export default SpaceInstitution
