import api from "@/api";
import Button from "@/components/Button";
import Pagination from "@/components/Pagination";
import { SmsHomeSubject } from "@/models/home";
import { SysMonth } from "@/models/select";
import { ButtonListRow, PaginationRow, TableRow } from "@/models/type";
import { debounceClick, getFile, typeWatch } from "@/utils";
import { ElMessageBox, ElNotification } from "element-plus";
import { Options, Vue } from "vue-class-component";
@Options({
  watch: {
    type(val?: string): void {
      this.title = typeWatch(val)
    },
    srcNum(num: number): void {
      if(num) {
        this.imgViewerShow()
      }
    },
  }
})
export default class FirstClassTimeTable extends Vue {
  total = 0
  srcNum = 0
  title = ''
  type = ''
  detailBool = false
  yearList: any[] = []

  monthList: any[] = []

  list: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'classTabelAdd' }
  ]

  tableData: Array<any> = []

  tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any): JSX.Element => <span>{ scope.$index + 1 }</span>
    } },
    { label: '时间', prop: 'month' },
    { label: '主题', prop: 'title' },
    { label: '时间表', slot: {
      default: (scope: any): JSX.Element => <span class="pointer" onClick={() => this.imgShow(scope.row.imgPath)}>{ scope.row.imgPath ? '已上传' : '未上传' }</span>
    } },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class="span-button-primary" onClick={ () => this.classEdit(scope.row) }>编辑</span>
        <span class="span-button-danger" onClick={ () => this.classDelete(scope.row.id, scope.$index) }>删除</span>
      </div>
    } },
  ]

  monthProps: any = {}

  imgShow(url: string): void {
    this.url = url
    this.srcList = this.tableData.map(item => item.imgPath) // 将类型为图片的给srcList
    this.srcNum++
  }

  classDelete(id: number, index: number): void {
    ElMessageBox.confirm('确定删除该课程表？', '删除课程表', {
      type: 'warning'
    }).then(() => {
      api.homeSubjectDeleteHomeSubject([id]).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除成功'
        })
        this.tableData.splice(index, 1)
      })
    }).catch(err => {
      return err
    })
  }
  classEdit(row: SmsHomeSubject): void {
    this.type = 'edit'
    const obj = Object.assign({}, row)
    api.selectGetMonthById(obj.monthId as number).then(res => {
      obj.monthId = [`jinx_${res.data.year}`, obj.monthId as number]
      this.classForm = obj
      this.detailBool = true
    })
  }

  classForm: SmsHomeSubject = {
    title: '',
    imgPath: '',
    monthId: [],
    month: '',
  }

  formRules: any = {}
  loading = false
  url = ''
  srcList: string[] = []

  /**
   * @description 列表
   * @author Jinx
   * @date 2021-30-12 16:08:19
   * @returns {*}  {JSX.Element}
   * @memberof FirstHome
   */
   tableDom(): JSX.Element {
    return (
      <el-table
        data={ this.tableData }
      >
        {this.tableList.map(item => (
          <el-table-column
          prop={ item.prop }
          label={ item.label }
          align={ item.align || 'center' }
          v-slots={ item.slot }
        />
        ))}
      </el-table>
    )
  }

  /**
   * @description 按钮点击操作
   * @author Jinx
   * @date 2021-08-18 18:14:42
   * @param {string} click
   * @memberof FirstClassTimeTable
   */
  buttonClick(click: string): void {
    this[click]()
  }

  classTabelAdd(): void {
    this.type = 'add'
    this.detailBool = true
  }

  getList(): void {
    const __pagination = this.$refs.pagination as any
    const { pageSize, pageNum } = __pagination.paginationObj as PaginationRow
    api.homeSubjectGetHomeSubjectList({ pageSize, pageNum }).then(res => {
      this.total = res.data.total
      this.tableData = res.data.list
    })
  }

  detailClose(): void {
    this.detailBool = false
    this.type = ''
    this.classForm = {
      title: '',
      imgPath: '',
      month: '',
      monthId: []
    }
  }

  detailDom(): JSX.Element {
    const slots: any = {
      footer: (): JSX.Element => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose } >取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
        </span>
      )
    }

    return (
      <el-dialog
        title={ `${ this.title }课程表` }
        v-model={ this.detailBool }
        before-close={ this.detailClose }
        width="30%"
        v-slots={ slots }
      >
        <el-form
          ref="form"
          model={ this.classForm }
          rules={ this.formRules }
          label-width="120px"
          class="el-form-tao"
        >
          <el-form-item label="月份" prop="monthId">
            <el-cascader v-model={ this.classForm.monthId } props={ this.monthProps } style="width:100%" onChange={ this.monthChange } />
          </el-form-item>
          <el-form-item label="主题" prop="title">
            <el-input v-model={ this.classForm.title } clearable placeholder="请输入主题" />
          </el-form-item>
          <el-form-item label="时间表" prop="title">
              { this.loading
                ? <span class="el-form-tao-btn" >上传中...</span>
                : <span class="el-form-tao-btn" onClick={ this.uploadFile }>{ this.classForm.imgPath ? '重新上传' : '上传' }</span>}
              {this.classForm.imgPath && <span class="el-form-tao-view" onClick={this.imgView} >预览</span>}
          </el-form-item>
        </el-form>
      </el-dialog>
    )
  }

  monthChange(val: Array<number | string>): void {
    const monthId = val[1]
    this.classForm.month = this.monthList.find(item => item.id === monthId)?.fullName
  }

  uploadFile(): void {
    getFile(files => {
      if(!files.length) {
        return
      }
      const formData = new FormData()
      formData.append('file', files[0])
      this.loading = true
      api.commonOssUpload(formData).then(res => {
        this.classForm.imgPath = res.data
        ElNotification({
          title: '成功',
          type: 'success',
          message: `上传课程表成功`
        })
        this.loading = false
      })
    }, 'HOME_CLASS', 'image/*')
  }

  imgView(): void {
    const url = this.classForm.imgPath as string
    this.url = url
    this.srcList = [url]
    this.imgViewerShow()
  }

  imgViewerShow(): void {
    const __img =  this.$refs.img as any
    __img.showViewer = true
  }

  imgViewerDom(): JSX.Element {
    return <el-image
      ref="img"
      style="width: 0; height: 0; z-index: 54188;"
      src={ this.url }
      preview-src-list={ this.srcList }
    >
    </el-image>
  }

  submit(): void {
    const __form = this.$refs.form as any
    __form.validate(v => {
      if(v) {
        const form = Object.assign({}, this.classForm)
        form.monthId = form.monthId && form.monthId[1]
        this.type === 'add'
          ? this.submitAdd(form)
          : this.submitEdit(form)
      } else {
        return false
      }
    })
  }

  submitAdd(form: SmsHomeSubject): void {
    delete form.id
    api.homeSubjectAddHomeSubject(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '添加课程表成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  submitEdit(form: SmsHomeSubject): void {
    api.homeSubjectEditHomeSubject(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '编辑课程表成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  getMonthList(): void {
    this.monthProps = {
      lazy: true,
      lazyLoad: (node, resolve) => {
        const { level } = node
        if(level > 0) {
          api.selectGetMonth({ year: node.data.label }).then(res => {
            this.monthList = res.data
            const nodes = res.data.map(item => ({
              value: item.id,
              label: item.fullName,
              leaf: level >= 1
            }))
            resolve(nodes)
          })
        } else {
          api.selectGetYear().then(res => {
            const nodes = res.data.map(item => ({
              value: `jinx_${item}`,
              label: item,
              leaf: level >= 1
            }))
            resolve(nodes)
          })
        }
      }
    }
  }

  created(): void {
    this.getMonthList()
  }

  render(): JSX.Element {
    return (
      <div class="first-classTimeTable">
        <Button list={ this.list } onClick={ this.buttonClick } />
        {this.tableDom()}
        <Pagination ref="pagination" total={ this.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
        {this.detailBool && this.detailDom()}
        {this.imgViewerDom()}
      </div>
    )
  }

  mounted(): void {
    this.getList()
  }
}
