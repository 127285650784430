import Tabs from "@/components/Tabs";
import { TabListRow } from "@/models/type";
import { Options, Vue } from "vue-class-component";
import FirstClassTimeTable from "./components/classTimeTable";
import FirstHome from "./components/home";

import './index.scss'

/**
 * @description 首页管理
 * @author Jinx
 * @date 2021-08-19 18:37:14
 * @export
 * @class First
 * @extends {Vue}
 */
@Options({
  components: {
    Tabs, FirstHome, FirstClassTimeTable
  }
})
export default class First extends Vue {

  private activeName = 'home'

  public tabList?: Array<TabListRow> = [
    { title: '首页', activeName: 'home' },
    { title: '课程时间表', activeName: 'classTimeTable' }
  ]


  private slots: any = {
    home: () => <FirstHome />,
    classTimeTable: () => <FirstClassTimeTable />
  }

  activeChange(keyName: string): void {
    this.activeName = keyName
  }

  render(): JSX.Element {
    return (
      <div class="first">
        <div class="first-content">
          <Tabs
            tabList={ this.tabList as unknown[] }
            v-slots={ this.slots }
            activeName={ this.activeName }
            onActivechange={ this.activeChange }
          />
        </div>
      </div>
    )
  }
}
