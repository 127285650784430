import api from "@/api";
import Button from "@/components/Button";
import Pagination from "@/components/Pagination";
import { SysTeam } from "@/models/home";
import { ButtonListRow, PaginationRow, TableRow } from "@/models/type";
import { debounceClick, getFile, typeWatch } from "@/utils";
import { isEmail, isEnName } from "@/utils/validate";
import { ElMessageBox, ElNotification } from "element-plus";
import { defineComponent } from "vue";

/**
 * 团队成员
 *  @type {*} */
const SpaceTeam = defineComponent({
  data() {
    const emailValidate = (rule, value, callback): void => {
      if(!value) {
        callback(new Error('邮箱不能为空'))
      } else if (!isEmail(value)) {
        callback(new Error('请检查邮箱格式是否正确'))
      } else {
        callback()
      }
    }

    const enNameValidate = (rule, value, callback): void => {
      if(!value) {
        callback(new Error('英文名称不能为空'))
      } else if (!isEnName(value)) {
        callback(new Error('名称只能是英文'))
      } else {
        callback()
      }
    }
    return {
      tableData: [] as Array<any>,
      detailBool: false,
      title: '',
      type: '',
      total: 0,
      loading: false,
      buttonList: [
        { title: '添加', icon: 'el-icon-plus', click: 'teamAdd' }
      ] as Array<ButtonListRow>,
      tableList: [
        { label: '序号', slot: {
          default: (scope: any): JSX.Element => <span>{ scope.$index + 1 }</span>
        } },
        { label: '中文名', prop: 'cnName' },
        { label: '英文名', prop: 'enName' },
        { label: '身份', prop: 'identity' },
        { label: '格言', prop: 'motto' },
        { label: '邮箱', prop: 'email' },
        { label: '操作', slot: {
          default: scope => <div>
            <span class="span-button-primary" onClick={ () => this.teamEdit(scope.row) }>编辑</span>
            <span class="span-button-danger" onClick={ () => this.teamDelete(scope.row.id) }>删除</span>
          </div>
        } }
      ] as Array<TableRow>,
      teamForm: {
        cnName: '',
        enName: '',
        motto: '',
        imgPath: '',
        identity: '',
        email: ''
      } as SysTeam,
      formRules: {
        cnName: [
          { required: true, message: '请输入中文名称' }
        ],
        enName: [
          { required: true, validator: enNameValidate }
        ],
        email: [
          { required: true, validator: emailValidate }
        ]
      },
      url: '',
      srcList: [] as Array<string>
    }
  },
  watch: {
    type(val: string) {
      this.title = typeWatch(val)
    }
  },
  methods: {
    buttonClick(click: string): void {
      this[click]()
    },
    teamAdd(): void {
      this.type = 'add'
      this.detailBool = true
    },
    teamEdit(row: SysTeam): void {
      this.teamForm = Object.assign({}, row)
      this.type = 'edit'
      this.detailBool = true
    },
    teamDelete(id: number): void {
      ElMessageBox.confirm('确定删除该团队成员？', '删除成员', {
        type: 'warning'
      }).then(() => {
        api.homeTeamDeleteTeam([id]).then(() => {
          ElNotification({
            title: '成功',
            type: 'success',
            message: '删除团队成员成功'
          })
          this.getList()
        })
      }).catch(err => {
        return err
      })
    },
    getList(): void {
      const __pagination = this.$refs.pagination as any
      const { pageSize, pageNum } = __pagination.paginationObj as PaginationRow

      api.homeTeamGetTeamList({ pageSize, pageNum }).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
      })
    },
    detailDom(): JSX.Element {
      const slots: any = {
        footer: (): JSX.Element => (
          <span class="dialog-footer">
            <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose } >取 消</button>
            <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
          </span>
        )
      }

      return (
        <el-dialog
          title={ `${ this.title }成员` }
          v-model={ this.detailBool }
          before-close={ this.detailClose }
          width="30%"
          v-slots={ slots }
        >
          <el-form
            ref="form"
            model={ this.teamForm }
            rules={ this.formRules }
            label-width="120px"
            class="el-form-tao"
          >
            <el-form-item label="中文名" prop="cnName">
              <el-input v-model={ this.teamForm.cnName } clearable placeholder="请输入中文名" />
            </el-form-item>
            <el-form-item label="英文名" prop="enName">
              <el-input v-model={ this.teamForm.enName } clearable placeholder="请输入英文名" />
            </el-form-item>
            <el-form-item label="身份" prop="identity">
              <el-input v-model={ this.teamForm.identity } clearable placeholder="请输入身份" />
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model={ this.teamForm.email } clearable placeholder="请输入邮箱" />
            </el-form-item>
            <el-form-item label="格言" prop="motto">
              <el-input type="textarea" v-model={ this.teamForm.motto } clearable placeholder="请输入格言" maxlength={ 15 } show-word-limit />
            </el-form-item>
            <el-form-item label="图片" prop="imgPath">
              <div>
                { this.loading
                  ? <span class="el-form-tao-btn" >上传中...</span>
                  : <span class="el-form-tao-btn" onClick={ this.uploadFile }>{ this.teamForm.imgPath ? '重新上传' : '上传' }</span>}
                {this.teamForm.imgPath && <span class="el-form-tao-view" onClick={this.someView} >预览</span>}
              </div>
            </el-form-item>
          </el-form>
        </el-dialog>
      )
    },

    uploadFile(): void {
      getFile(files => {
        if(!files.length) {
          return
        }
        const formData = new FormData()
        formData.append('file', files[0])
        this.loading = true
        api.commonOssUpload(formData).then(res => {
          this.teamForm.imgPath = res.data
          ElNotification({
            title: '成功',
            type: 'success',
            message: `上传图片成功`
          })
          this.loading = false
        })
      }, 'AVATAR', 'image/*')
    },

    someView(): void {
      const url = this.teamForm.imgPath as string
      this.url = url
      this.srcList = [url]
      this.imgViewerShow()
    },

    submit(): void {
      const __form = this.$refs.form as any
      __form.validate(v => {
        if(v) {
          const form = Object.assign({}, this.teamForm)
          this.type === 'add'
           ? this.submitAdd(form)
           : this.submitEdit(form)
        } else {
          return false
        }
      })
    },
    submitAdd(form: SysTeam): void {
      delete form.id
      api.homeTeamAddTeam(form).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '添加成员信息成功'
        })
        this.detailClose()
        this.getList()
      })
    },
    submitEdit(form: SysTeam): void {
      api.homeTeamEditTeam(form).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '编辑成员信息成功'
        })
        this.detailClose()
        this.getList()
      })
    },
    detailClose(): void {
      this.detailBool = false
      this.type = ''
      this.teamForm = {
        cnName: '',
        enName: '',
        motto: '',
        imgPath: '',
        identity: '',
        email: ''
      }
    },
    imgViewerDom(): JSX.Element {
      return <el-image
        ref="img"
        style="width: 0; height: 0; z-index: 54188;"
        src={ this.url }
        preview-src-list={ this.srcList }
      >
      </el-image>
    },
    imgViewerShow(): void {
      const __img =  this.$refs.img as any
      __img.showViewer = true
    }
  },
  render(){
    return (
      <div class="space-team">
        <Button list={ this.buttonList } onClick={ this.buttonClick } />
        <el-table
          data={ this.tableData }
        >
          {this.tableList.map(item => (
            <el-table-column
              prop={ item.prop }
              label={ item.label }
              width={ item.width }
              align={ item.align || 'center' }
              v-slots={ item.slot }
            />
          ))}
        </el-table>
        <Pagination ref="pagination" total={ this.total } />
        {this.imgViewerDom()}
        {this.detailBool && this.detailDom()}
      </div>
    )
  },
  mounted() {
    this.getList()
  }
})

export default SpaceTeam
