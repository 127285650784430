import api from "@/api";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import { SmsShow } from "@/models/activity";
import { ButtonListRow, OptionsRow, PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { parseTime, typeWatch } from "@/utils";
import { ElMessageBox, ElNotification } from "element-plus";
import { Vue, Watch } from "vue-property-decorator";
import { h } from "vue";
import ShowDetail from "./detail";
import './index.scss'

export default class Show extends Vue {
  public type = ''
  public title = ''
  public total = 0
  public listSum = 0
  public searchObj: any = {}

  public detailRow: SmsShow = {}

  public articleList: any[] = []

  public statusList: Array<OptionsRow> = [
    { name: '未开启', value: 0 },
    { name: '报名中', value: 1 },
    { name: '已开始', value: 2 },
    { name: '已结束', value: 3 }
  ]

  private searchList: Array<SearchListRow> = [
    { label: '展览名称', type: 'input', placeholder: '请输入展览名称', submitKey: 'title' },
    { label: '展览时间', icon: 'svg_3', type: 'time', submitKey: ['startShowTime', 'endShowTime'] }
  ]
  private buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'showAdd' }
  ]

  private tableData: Array<any> = []
  public tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any): JSX.Element => <span>{ scope.$index + 1 }</span>
    } },
    { label: '展览名称', prop: 'title' },
    { label: '展览时间', slot: {
      default: (scope: any): JSX.Element => <div>
        <span>{ parseTime(new Date(scope.row.signUpStartTime).getTime(), '{y}-{m}-{d}') }</span>
        <br />
        <span>{ parseTime(new Date(scope.row.signUpEndTime).getTime(), '{y}-{m}-{d}') }</span>
      </div>
    } },
    { label: '展览地点', prop: 'position' },
    { label: '驻场艺术家', slot: {
      default: (scope: any): JSX.Element => <span>{ this.articleList.find(item => item.id === scope.row.articleId)?.title }</span>
    } },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class="span-button-primary" onClick={ () => this.showEdit(scope.row) }>详情</span>
        <span class="span-button-danger" onClick={ () => this.showDelete(scope.row.id) }>删除</span>
      </div>
    } },
  ]

  @Watch('type')
  titleChange(val?: string): void {
    this.title = typeWatch(val)
    switch(val){
      case 'add':
        break
      case 'edit':
        this.detailEdit()
        break
      default:
        this.getList()
    }
  }

  detailEdit(): void {
    this.$nextTick(() => {
      const __ShowDetail = this.$refs.ShowDetail as ShowDetail
      const obj = this.detailRow
      obj.signUpTime = [obj.signUpStartTime as Date, obj.signUpEndTime as Date]
      __ShowDetail.showForm = obj
    })
  }

  showAdd(): void {
    this.type = 'add'
    this.tableData = []
  }

  showEdit(row: SmsShow): void {
    this.type = 'edit'
    this.detailRow = Object.assign({}, row)
    this.tableData = []
  }

  showDelete(id: number): void {
    ElMessageBox.confirm('确定删除该展览？', '删除展览', {
      type: 'warning'
    }).then(() => {
      api.activeShowDeleteShow([id]).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除展览成功'
        })
        this.listSum++
      })
    }).catch(() => {
      return false
    })
  }

  @Watch('listSum')
  listSumChange(sum: number): void {
    if(sum) {
      this.getList()
    }
  }

  buttonClick(click: string): void {
    this[click]()
  }

  onSearch(row: never): void {
    this.searchObj = Object.assign({}, row)
    this.getList()
  }

  tableDom(): JSX.Element {
    return (
      <el-table
        ref="table"
        data={ this.tableData }
      >
        {this.tableList.map(item => (
          <el-table-column
          prop={ item.prop }
          label={ item.label }
          align={ item.align || 'center' }
          v-slots={ item.slot }
        />
        ))}
      </el-table>
    )
  }

  getData(): void {
    Promise.all([
      api.activeArticleGetArticleListSelect()
    ]).then(res => {
      this.articleList = res[0].data
    })
  }

  getList(): void {
    this.$nextTick(() => {
      const __pagination = this.$refs.pagination as any
      const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow
      api.activeShowGetShowList({ pageNum, pageSize, ...this.searchObj }).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
      })
    })
  }

  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return this.type
      ? h(ShowDetail, { title: this.title, ref: 'ShowDetail' })
      : <div class="show">
        <Card title="活动查询" class="show-card">
          <Search list={ this.searchList } class="show-search" onSearch={ this.onSearch } />
        </Card>
        <Card title="活动管理">
          <div class="show-search">
            <Button list={ this.buttonList } onClick={ this.buttonClick } />
            {this.tableDom()}
            <Pagination ref="pagination" total={ this.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
          </div>
        </Card>
      </div>
  }

  mounted(): void {
    this.getList()
  }
}
