import api from "@/api";
import { SysPropertiesItemList } from "@/models/home";
import { debounceClick } from "@/utils";
import { ElNotification } from "element-plus";
import { defineComponent, onMounted, ref } from "vue";

/**
 * 空间介绍
 *  @type {*} */
const SpaceIntroduction = defineComponent({
  setup() {

    const dataList: any = ref([])

    const textareaDom = (title: string, model: any, index: number): JSX.Element => {
      return (
        <div class="space-introduction-textarea">
          <span class="space-introduction-textarea-title">{ title }</span>
          <el-input
            class="space-introduction-textarea-input"
            v-model={ model }
            type="textarea"
            onInput={updateValue.bind(this, index)}
            maxlength={ 150 }
            show-word-limit
          />
        </div>
      )
    }

    /**
     * @description 实现双绑
     * @author Jinx
     * @date 2021-08-20 10:21:42
     * @param {*} index
     * @param {*} e
     */
    function updateValue(index, e) {
      dataList.value[index].paramValue = e
    }

    function getData(): void {
      api.homeSpaceGetSpaceIntroduce().then(res => {
        dataList.value = res.data
      })
    }

    /**
     * @description 提交表单
     * @author Jinx
     * @date 2021-08-20 10:36:54
     */
    function submit(): void {
      api.homeSpaceEditSpaceIntroduce(dataList.value).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '保存成功'
        })
      })
    }

    onMounted(() => {
      getData()
    })

    return () => (
      <div class="space-introduction">
        <el-row gutter={ 90 }>
          {dataList.value.map((item: SysPropertiesItemList, index: number): JSX.Element => (
            <el-col lg={ 12 } sm = { 24 }>
              {textareaDom(item.title as string, item.paramValue, index)}
            </el-col>
          ))}
        </el-row>
        <div class="space-introduction-submit">
          <span class="space-introduction-submit-btn pointer" onClick={() => debounceClick(submit)} >保存</span>
        </div>
      </div>
    )
  }
})

export default SpaceIntroduction
