import api from "@/api";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import { SmsActivityArticle } from "@/models/activity";
import { smsHomeImgStatus } from "@/models/home";
import { ButtonListRow, OptionsRow, PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { debounceClick, getFile, typeWatch } from "@/utils";
import { isEmail } from "@/utils/validate";
import { ElMessageBox, ElNotification } from "element-plus";
import { Options, Vue } from "vue-class-component";
import './index.scss'

@Options({
  watch: {
    type(val?: string): void {
      this.title = typeWatch(val)
    },
    swithSum(sum: number): void {
      if(sum) {
        this.getList()
      }
    }
  }
})
export default class Art extends Vue {

  private swithSum = 0

  private emailValidate = (rule, value, callback): void => {
    if(!value) {
      callback(new Error('邮箱不能为空'))
    } else if (!isEmail(value)) {
      callback(new Error('请检查邮箱格式是否正确'))
    } else {
      callback()
    }
  }

  private searchList: Array<SearchListRow> = [
    { label: '艺术家名称', type: 'input', submitKey: 'title', placeholder: '请输入艺术家名称' }
  ]

  private buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'artAdd' },
  ]

  private classList: Array<OptionsRow> = []
  private artList: Array<OptionsRow> = []

  private statusList: Array<OptionsRow> = [
    { value: 1, name: '展示'},
    { value: 0, name: '不展示'}
  ]

  private total = 0
  private detailBool = false
  private loading = false
  private title = ''
  private type = ''

  public artForm: SmsActivityArticle = {
    title: '',
    imgUrl: '',
    introduce: '',
    email: '',
    phone: '',
  }

  private formRules: any = {
    title: [
      { required: true, message: '请输入导师姓名' }
    ],
    phone: [
      { required: true, message: '请输入导师手机号' }
    ],
    subjectType: [
      { required: true, message: '请选择课程类型', trigger: 'change' }
    ],
    email: [
      { required: true, validator: this.emailValidate }
    ]
  }

  private searchObj: any = {}

  private url = ''
  private srcList: Array<string> = []

  public tableData: Array<any> = []
  public tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any): JSX.Element => <span>{ scope.$index + 1 }</span>
    } },
    { label: '名称', prop: 'title' },
    { label: '联系方式', prop: 'phone' },
    { label: '邮箱', prop: 'email' },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class="span-button-primary" onClick={ () => this.artEdit(scope.row) }>编辑</span>
        <span class="span-button-danger" onClick={ () => this.artDelete(scope.row.id, scope.$index) }>删除</span>
      </div>
    } },
  ]

  onSwith(id: number, status: smsHomeImgStatus): void {
    api.subjectTutorEditTutorStatus(id, status).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '修改展示状态成功'
      })
      this.swithSum++
    })
  }

  buttonClick(click: string): void {
    this[click]()
  }

  artAdd(): void {
    this.type = 'add'
    this.detailBool = true
  }

  artEdit(row: SmsActivityArticle): void {
    this.artForm = Object.assign({}, row)
    this.type = 'edit'
    this.detailBool = true
  }

  artDelete(id: number, index: number): void {
    ElMessageBox.confirm('确定删除该导师信息？', '删除导师', {
      type: 'warning'
    }).then(() => {
      api.activeArticleDeleteArticle([id]).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除成功'
        })
        this.tableData.splice(index, 1)
      })
    }).catch(err => {
      return err
    })
  }

  getList(): void {
    const __pagination = this.$refs.pagination as any
    const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow

    api.activeArticleGetArticleList({ pageNum, pageSize, ...this.searchObj }).then(res => {
      this.tableData = res.data.list
      this.total = res.data.total
    })
  }

  tableDom(): JSX.Element {
    return (
      <el-table
        ref="table"
        data={ this.tableData }
      >
        {this.tableList.map(item => (
          <el-table-column
          prop={ item.prop }
          label={ item.label }
          align={ item.align || 'center' }
          v-slots={ item.slot }
        />
        ))}
      </el-table>
    )
  }

  onSearch(obj: never): void {
    this.searchObj = Object.assign({}, obj)
    this.getList()
  }


  detailDom(): JSX.Element {
    const slots: any = {
      footer: (): JSX.Element => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose } >取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
        </span>
      )
    }

    return (
      <el-dialog
        title={ `${ this.title }艺术家` }
        v-model={ this.detailBool }
        before-close={ this.detailClose }
        width="30%"
        v-slots={ slots }
      >
        <el-form
          ref="form"
          model={ this.artForm }
          rules={ this.formRules }
          label-width="120px"
          class="el-form-tao"
        >
          <el-form-item label="名称" prop="title">
            <el-input v-model={ this.artForm.title } clearable placeholder="请输入导师姓名" />
          </el-form-item>
          <el-form-item label="联系方式" prop="phone">
            <el-input v-model={ this.artForm.phone } clearable placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model={ this.artForm.email } clearable placeholder="请输入邮箱" />
          </el-form-item>
          <el-form-item label="简介" prop="introduce">
            <el-input type="textarea" v-model={ this.artForm.introduce } clearable placeholder="请输入导师简介" max={120} show-word-limit />
          </el-form-item>
          <el-form-item label="照片" prop="imgUrl">
            <div>
              { this.loading
                ? <span class="el-form-tao-btn" >上传中...</span>
                : <span class="el-form-tao-btn" onClick={ this.uploadFile }>{ this.artForm.imgUrl ? '重新上传' : '上传' }</span>}
              {this.artForm.imgUrl && <span class="el-form-tao-view" onClick={this.someView} >预览</span>}
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    )
  }

  someView(): void {
    const url = this.artForm.imgUrl as string
    this.url = url
    this.srcList = [url]
    this.imgViewerShow()
  }

  imgViewerShow(): void {
    const __img =  this.$refs.img as any
    __img.showViewer = true
  }

  imgViewerDom(): JSX.Element {
    return <el-image
      ref="img"
      style="width: 0; height: 0; z-index: 54188;"
      src={ this.url }
      preview-src-list={ this.srcList }
    >
    </el-image>
  }

  uploadFile(): void {
    getFile(files => {
      if(!files.length) {
        return
      }
      const formData = new FormData()
      formData.append('file', files[0])
      this.loading = true
      api.commonOssUpload(formData).then(res => {
        this.artForm.imgUrl = res.data
        ElNotification({
          title: '成功',
          type: 'success',
          message: `上传照片成功`
        })
        this.loading = false
      })
    }, 'TEACHER', 'image/*')
  }

  submit(): void {
    const __form = this.$refs.form as any
    __form.validate(v => {
      if (v) {
        const form = Object.assign({}, this.artForm)
        this.type === 'add'
          ? this.submitAdd(form)
          : this.submitEdit(form)
      } else {
        return false
      }
    })
  }

  submitAdd(form: SmsActivityArticle): void {
    delete form.id
    api.activeArticleAddArticle(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '添加导师成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  submitEdit(form: SmsActivityArticle): void {
    api.activeArticleEditArticle(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '编辑导师成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  detailClose(): void {
    this.detailBool = false
    this.type = ''
    this.artForm = {
      title: '',
      imgUrl: '',
      introduce: '',
      email: '',
      phone: '',
    }
  }

  render(): JSX.Element {
    return (
      <div class="art">
        <Card title="艺术家查询" class="art-card">
          <Search list={ this.searchList } class="art-search" onSearch={ this.onSearch } />
        </Card>
        <Card title="艺术家管理">
          <div class="art-search">
            <Button list={ this.buttonList } onClick={ this.buttonClick } />
            {this.tableDom()}
            <Pagination ref="pagination" total={ this.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
          </div>
        </Card>
        {this.imgViewerDom()}
        {this.detailBool && this.detailDom()}
      </div>
    )
  }

  mounted(): void {
    this.getList()
  }
}
