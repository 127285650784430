import api from "@/api";
import ArticleInfo from "@/components/ArticleInfo";
import Card from "@/components/Card";
import ImageCell, { FormRule } from "@/components/ImageCell";
import SvgIcon from "@/components/SvgIcon";
import { SmsShow } from "@/models/activity";
import { ElNotification } from "element-plus";
import { h } from "vue";
import { Vue, Prop, Options } from "vue-property-decorator";
import Show from ".";

@Options({})
export default class ShowDetail extends Vue {
  @Prop(String) readonly title!: string

  public showForm: SmsShow = {
    title: '',
    position: '',
    signUpTime: [],
    introduce: '',
    mainImg: '',
    coverImg: '',
    otherImg: '',
    articleId: undefined,
    articleIntroduce: '',
    articleImg: ''
  }
  private formRules: any = {
    signUpTime: [
      { required: true, message: '请选择报名时间', trigger: 'change' }
    ]
  }

  public mainImgBool = false
  public coverImgBool = false
  public otherImgBool = false
  public articleImgBool = false

  goBack(): void {
    const __parent = this.$parent as Show
    __parent.type = ''
    this.showForm = {
      title: '',
      position: '',
      signUpTime: [],
      introduce: '',
      mainImg: '',
      coverImg: '',
      otherImg: '',
      articleId: undefined,
      articleIntroduce: '',
      articleImg: ''
    }
  }

  onChangeImg(form: FormRule): void {
    this.showForm.coverImg = form.coverImg
    this.showForm.mainImg = form.mainImg
    this.showForm.otherImg = form.otherImg
  }

  detailDom(): JSX.Element {

    return <div class="show-detail">
      <el-row gutter={ 50 }>
        <el-col lg={ 8 } md={ 12 } sm={ 24 } class="show-detail-cell">
          <el-form-item label="展览名称" prop="title">
            <el-input v-model={ this.showForm.title } clearable placeholder="请输入展览名称" />
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } sm= { 24 } class="show-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_7" />
              <span>展览地点</span>
            </>}}
            prop="position"
          >
            <el-input v-model={ this.showForm.position } clearable placeholder="请输入展览地点" />
          </el-form-item>
        </el-col>
        <el-col lg={ 8 } md={ 12 } sm= { 24 } class="show-detail-cell">
          <el-form-item
            v-slots={{ label: () => <>
              <SvgIcon iconClass="svg_3" />
              <span>报名时间</span>
            </>}}
            prop="signUpTime"
          >
            <el-date-picker
              v-model={ this.showForm.signUpTime }
              type="daterange"
              range-separator="-"
              start-placeholder="年/月/日"
              end-placeholder="年/月/日"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col span={ 24 } class="show-detail-cell">
          <el-form-item prop="introduce" label="展览介绍" >
            <el-input type="textarea" v-model={ this.showForm.introduce } clearable style="width: 100%" maxlength={120} show-word-limit />
          </el-form-item>
        </el-col>
      </el-row>
      {h(ImageCell, {
        ref: 'image_cell',
        coverImg: this.showForm.coverImg,
        mainImg: this.showForm.mainImg,
        otherImg: this.showForm.otherImg,
        sizeType: 'show',
        onChangeImg: this.onChangeImg
      })}
      <div class="show-detail-submit" onClick={this.submit}>保存</div>
    </div>
  }

  submit(): void {
    const __form = this.$refs.form as any
    const __parent = this.$parent as Show

    const __imageCell = this.$refs.image_cell as ImageCell
    const __articleInfo = this.$refs.article_info as ArticleInfo

    __form.validate(v => {
      if (v) {
        const form = Object.assign({}, this.showForm, __imageCell.form, __articleInfo.form)

        form.signUpStartTime = form.signUpTime?.length ? form.signUpTime[0] : undefined
        form.signUpEndTime = form.signUpTime?.length ? form.signUpTime[1] : undefined

        delete form.signUpTime
        __parent.type === 'add'
          ? this.submitAdd(form)
          : this.submitEdit(form)
      } else {
        return false
      }
    })
  }

  submitAdd(form: SmsShow): void {
    delete form.id
    api.activeShowAddShow(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '添加展览成功'
      })
      this.goBack()
    })
  }

  submitEdit(form: SmsShow): void {
    api.activeShowEditShow(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '编辑展览成功'
      })
      this.goBack()
    })
  }

  render(): JSX.Element {
    return <el-form
      ref="form"
      model={ this.showForm }
      rules={ this.formRules }
      class="show"
    >
      <Card title={ `${ this.title }展览信息` } back onGoBack={ this.goBack } class="show-card">
        {this.detailDom()}
      </Card>
      <Card title="艺术家信息">
        {h(ArticleInfo, {
          articleId: this.showForm.articleId,
          articleImg: this.showForm.articleImg,
          articleIntroduce: this.showForm.articleIntroduce,
          ref: 'article_info'
        })}
      </Card>
    </el-form>
  }
}
