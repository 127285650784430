import Card from "@/components/Card"
import { DefaultRow, OptionsRow, PaginationRow, TableRow, ViewRow } from "@/models/type"
import { Options, Vue } from "vue-class-component"
import base from "@/style/base.scss"
import './index.scss'
import Pagination from "@/components/Pagination"
import api from "@/api"
import { parseTime } from "@/utils"
import { useRouter } from "vue-router"

/**
 * @description 首页
 * @author Jinx
 * @date 2021-49-11 10:08:37
 * @export
 * @class Home
 * @extends {Vue}
 */
@Options({
  components: { Pagination },
  computed: {
    variables(){
      return base
    }
  }
})
export default class Home extends Vue {

  total = 0
  public type = 'subject'
  public variables?: any
  public memberLevelList : Array<OptionsRow> = []
  public numViewList: Array<ViewRow> = []

  public router = useRouter()

  public orderStatusList: OptionsRow [] = [
    { name: '待付款', value: 0 },
    { name: '待发货', value: 1 },
    { name: '已发货', value: 2 },
    { name: '已完成', value: 3 },
    { name: '已关闭', value: 4 },
    { name: '无效订单', value: 5 },
    { name: '已评价', value: 6 },
  ]

  public userViewList: Array<DefaultRow> = []

  private tableList: Array<TableRow> = [
    { label: '报名时间', slot: {
      default: (scope: any) => <span>
        { parseTime(new Date(scope.row.signUpStartTime).getTime(), '{y}.{m}.{d}') } - {parseTime(new Date(scope.row.signUpEndTime).getTime(), '{y}.{m}.{d}')}
      </span>
    } },
    { label: '名称', prop: 'title'  },
    { label: '性质', slot: {
      default: () => <span>{this.type === 'subject' ? '课程' : '活动'}</span>
    }  },
    { label: '报名人数', prop: 'num', },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div class="table_primary pointer" onClick={() => this.goDetail(this.type)}>查看详情</div>
    } },
  ]

  private tableData?: Array<any> = []

  public list = (): JSX.Element => (
    <el-table data={ this.tableData } columns={ this.tableList } >
      { this.tableList.map(item => (
        <el-table-column
          prop={ item.prop }
          label={ item.label }
          align="center"
          v-slots={ item.slot }
        />
      )) }
    </el-table>
  )

  /**
   * @description 报名总览
   * @author Jinx
   * @date 2021-08-13 16:40:59
   * @returns {*}  {JSX.Element}
   * @memberof Home
   */
  signViewDom(): JSX.Element {
    const slot = {
      suffix: () => <div
        class="home-choose"
      >
        <div class={this.type === 'subject' ? 'homeactive' : ''} onClick={() => this.getList('subject')}>课程</div>
        <div class={this.type === 'activity' ? 'homeactive' : ''} onClick={() => this.getList('activity')}>活动</div>
      </div>
    }
    return (
      <Card title="报名总览" v-slots={slot}>
        {this.list()}
        <Pagination ref="pagination" total={ this.total } onCurrentChange={this.getView} onSizeChange={this.getView} />
      </Card>
    )
  }

  goDetail(type: string): void {
    switch(type){
      case 'subject':
        this.router.push({
          name: 'Classing'
        })
        break
      case 'activity':
        this.router.push({
          name: 'Doing'
        })
        break
      default:
        break
    }
  }

  getList(type: string): void {
    this.type = type
    this.getView()
  }

  /**
   * @description 用户总览
   * @author Jinx
   * @date 2021-08-13 16:40:46
   * @returns {*}  {JSX.Element}
   * @memberof Home
   */
  userViewDom(): JSX.Element {
    return (
      <Card class="home-mg" title="用户总览">
        <div class="home-user">
          {this.userViewList.map(item => (
            <div class="home-user__cell">
              <div class="home-user__num">
                <div class="home-user__value">{ item.value }</div>
                { item.amount && (
                  <div class={
                    ['home-user__amount', item.mode === '-' ? 'home-user__amount--down' : 'home-user__amount--up' ]}
                  >
                    { `${item.mode}${item.amount}` }
                  </div>
                ) }
              </div>
              <div class="home-user__title">{ item.name }</div>
            </div>
          ))}
        </div>
      </Card>
    )
  }

  /**
   * @description 商品总览
   * @author Jinx
   * @date 2021-08-13 16:39:03
   * @returns {*}  {JSX.Element}
   * @memberof Home
   */
  numViewDom(): JSX.Element {
    const { menuBg, menuHover } = this.variables // 拿到背景色

    return (
      <el-row gutter={ 65 }>
        {this.numViewList.map(item => (
          <el-col lg={ 12 } sm = { 24 }>
            <Card title={ item.title } class="home-mg">
              <div class="home-view">
                {item.viewList.map((i, ix) => (
                  <div
                    class={['home-view__cell', `home-view__cell${ix}`]}
                    style={{
                      backgroundColor: !ix && menuBg,
                      color: ix ? '#4A575D' : menuHover
                    }}
                  >
                    <div class={['home-view__num', `home-view__num${ix}`]} >{ i.value }</div>
                    <div class={['home-view__title', `home-view__title${ix}`]} >{ i.name }</div>
                  </div>
                ))}
              </div>
            </Card>
          </el-col>
        ))}
      </el-row>
    )
  }

  getData(): void {
    api.memberLevelList().then(res => {
      this.memberLevelList = res.data.map(item => ({ name: item.name, value: item.id }))
    })
  }

  getView(): void {
    const __pagination = this.$refs.pagination as any
    const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow
    Promise.all([
      api.systemGetMemberStatistics(),
      api.systemGetOrderStatistics(),
      api.systemGetProductStatistics(),
      api.systemGetSignUpStatistics({ pageNum, pageSize, type: this.type })
    ]).then(res => {
      // 用户
      const memberList: Array<DefaultRow> = []

      this.memberLevelList.forEach(item => {
        const obj: DefaultRow = {
          name: item.name,
          value: 0,
          id: item.value as number
        }
        memberList.push(obj)
      })
      memberList.forEach(i => {
        res[0].data.forEach(item => {
          if(item.member_level_id === i.id) {
            i.value = item.num
          }
        })
      })
      this.userViewList = memberList

      // 订单 商品  3 1 2

      const order_3 = res[1].data.find(item => item.status === 3)?.num || 0 // 已完成
      const order_1 = res[1].data.find(item => item.status === 1)?.num || 0 // 待发货
      const order_2 = res[1].data.find(item => item.status === 2)?.num || 0 // 待收货

      const productThen = res[2].data.find(item => item.publish_status === 1)?.num || 0 // 已上架
      const productYet =res[2].data.find(item => item.publish_status === 0)?.num || 0 // 未上架

      const numList: Array<ViewRow> = [
        {
          title: '商品总览',
          viewList: [
            { name: '全部', value: productThen + productYet },
            { name: '已上架', value: productThen },
            { name: '已下架', value: productYet },
          ]
        },
        {
          title: '订单总览',
          viewList: [
            { name: '已完成', value: order_3 },
            { name: '待发货', value: order_1 },
            { name: '待收货', value: order_2 }
          ]
        }
      ]

      this.numViewList = numList

      // 报名
      this.tableData = res[3].data.list
      this.total = res[3].data.total

    })
  }

  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return (
      <div class="home">
        {this.numViewDom()}
        {this.userViewDom()}
        {this.signViewDom()}
      </div>
    )
  }
  mounted(): void {
    this.getView()
  }
}
