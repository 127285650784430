import api from "@/api"
import Button from "@/components/Button"
import Card from "@/components/Card"
import Pagination from "@/components/Pagination"
import Search from "@/components/Search"
import Switch from "@/components/Switch"
import { ProductParam } from "@/models/product"
import { ButtonListRow, OptionsRow, PaginationRow, SearchListRow, TableRow } from "@/models/type"
import { ElMessageBox, ElNotification } from "element-plus"
import { Vue, Options } from "vue-class-component"
import CommodityDetail from "./detail"
import './index.scss'

@Options({
  watch: {
    srcNum(num: number): void {
      if(num) {
        this.imgViewerShow()
      }
    },
    switchNum(num: number): void {
      if(num) {
        this.getList()
      }
    },
    type(val: string): void {
      switch (val) {
        case 'add':
          break
        case 'edit':
          this.detailEdit()
          break
        default:
          this.getList()
      }
    }
  }
})
export default class Commodity extends Vue {

  public detailRow: any = {}

  public searchList: Array<SearchListRow> = []

  public buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'mallAdd' },
    { title: '删除', icon: 'el-icon-minus', type: 'danger', click: 'mallDelete' }
  ]
  private ids: Array<number> = []
  public type = ''
  private searchObj: any = {}
  public productTypeList: Array<OptionsRow> = []

  srcNum = 0
  switchNum = 0

  public publishStatusList: Array<OptionsRow> = [
    { name: '已上架', value: 1 },
    { name: '未上架', value: 0 },
  ]
  public expands: Array<any> = []
  public url = ''

  public srcList: Array<string> = []

  private tableData: Array<any> = []
  private skuStockList: Array<any> = []
  private tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any) => <span>{scope.$index + 1}</span>
    } },
    { label: '商品编号', prop:'id' },
    { label: '商品状态', slot: {
      default: (scope: any) => <Switch switchBool={ scope.row.publishStatus } bindValue={{ open: 1, close: 0 }} onSwitch={val => this.switchClick(val, scope.row.id)} />
    } },
    { label: '商品首图', slot: {
      default: (scope: any) => <img class="mall-img pointer" src={ scope.row.coverImg } alt="" onClick={() => this.imgShow(scope.row.coverImg, 'coverImg')} />
    } },
    { label: '商品名称', prop: 'name' },
    { label: '类型', prop: 'productCategoryName' },
    { label: '货号', prop: 'productSn' },
    { label: '价格', prop: 'originalPrice' },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span
          class={["span-button-primary", scope.row.loading && 'el-icon-loading']}
          onClick={ () => this.mallEdit(scope.row, scope.$index) }
        >{!scope.row.loading && '编辑'}</span>
        <span class="span-button-danger" onClick={ () => this.mallDelete(scope.row.id) }>删除</span>
      </div>
    } }
  ]

  private skuTableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any) => <span>{scope.$index + 1}</span>
    } },
    { label: '商品编号', prop:'id' },
    { label: '商品首图', slot: {
      default: (scope: any) => <img class="mall-img pointer" src={ scope.row.pic } alt={ scope.row.title } onClick={() => this.imgShow(scope.row.pic, 'pic')} />
    } },
    { label: '商品名称', prop: 'title' },
    { label: '货号', prop: 'skuCode' },
    { label: '库存', prop: 'stock' },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class="span-button-danger" onClick={ () => this.mallDelete(scope.row.id) }>删除</span>
      </div>
    } }
  ]

  detailEdit(): void {
    this.$nextTick(() => {
      const __commodity = this.$refs.commodity as CommodityDetail
      __commodity.commodityForm = this.detailRow as ProductParam
    })
  }

  /**
   * @description 图片dom
   * @author Jinx
   * @date 2021-10-19 19:53:21
   * @returns {*}  {JSX.Element}
   * @memberof Commodity
   */
  imgViewerDom(): JSX.Element {
    return <el-image
      ref="img"
      style="width: 0; height: 0; z-index: 54188;"
      src={ this.url }
      preview-src-list={ this.srcList }
    >
    </el-image>
  }

  /**
   * @description 图片展示
   * @author Jinx
   * @date 2021-08-19 14:27:43
   * @memberof FirstHome
   */
   imgViewerShow(): void {
    const __img =  this.$refs.img as any
    __img.showViewer = true
  }

  imgShow(url: string, prop: string): void {
    this.url = url
    this.srcList = this.skuStockList.map(item => item[prop]) // 将类型为图片的给srcList
    this.srcNum++
  }

  switchClick(publishStatus, ids): void {
    api.productUpdatePublishStatus({
      publishStatus,
      ids
    }).then(() => {
      ElNotification({
        type: 'success',
        message: '操作成功'
      })
      this.switchNum++
    })
  }

  public total = 0
  public articleList: Array<any> = []

  bttonClick(click: string): void {
    this[click]()
  }

  getData(): void {
    Promise.all([
      api.productCateListGorywithChildren()
    ]).then(res => {
      this.productTypeList = res[0].data.map(item => ({ name: item.name, value: item.id }))
      this.searchList = [
        { label: '商品名称', type: 'input', submitKey: 'keyword' },
        { label: '商品编号', type: 'input', submitKey: 'id' },
        { label: '商品货号', type: 'input', submitKey: 'productSn' },
        { label: '商品类型', type: 'select', options: this.productTypeList, submitKey: 'productCategoryId' },
        { label: '商品状态', type: 'select', options: this.publishStatusList, submitKey: 'publishStatus' },
      ]
    })
  }

  mallAdd(): void {
    this.type = 'add'
  }

  mallEdit(row: ProductParam, index): void {
    if(this.tableData[index].loading) {
      return
    }
    const obj = Object.assign({}, row) as ProductParam
    this.tableData[index].loading = true
    api.productUpdateInfo(obj.id as number).then(res => {
      res.data.skuStockList = res.data.skuStockList && res.data.skuStockList.length > 0 ? res.data.skuStockList : []
      this.detailRow = res.data
      this.tableData[index].loading = false
      this.type = 'edit'
      this.tableData = []
    }).catch(() => this.tableData[index].loading = false)
  }

  mallDelete(id: number): void {
    const ids = id ? [id] : this.ids
    if(!(ids.length > 0)) {
      ElNotification({
        type: 'warning',
        message: '请选择要删除的商品列表'
      })
      return
    }
    ElMessageBox.confirm('确定删除该商品？', '删除商品', {
      type: 'warning'
    }).then(() => {
      api.productUpdateDeleteStatus(ids).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除成功'
        })
        this.switchNum++
      })
    }).catch(() => {
      return false
    })
  }

  onSearch(row: never): void {
    this.searchObj = Object.assign({}, row)
    this.getList()
  }

  getList(): void {
    this.tableData = [] // 防止样式不更新 重置一次
    this.$nextTick(() => {
      const __pagination = this.$refs.pagination as any
      const { pageNum, pageSize} = __pagination.paginationObj as PaginationRow
      api.productList({ pageNum, pageSize, ...this.searchObj, type: 0 }).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
      })
    })
  }

  selectionChange(row: any): void {
    this.ids = row.map(item => item.id) as Array<number>
  }
  ExpandChange(row: ProductParam, expanded: Array<ProductParam>): void {
    if (expanded.length) {
      this.expands = []
      if (row) {
        this.skuStockList = []
        api.productUpdateInfo(row.id as number).then(res => {
          const skuStockList = res.data.skuStockList
          this.skuStockList = skuStockList
        })
        this.expands.push(row.id)
      }
    } else {
      this.expands = []
    }
  }

  tableDom(): JSX.Element {
    const slot = {
      default: (scope: {row: ProductParam, $index: number}) => {
        return <el-table
          data={this.skuStockList}
        >
          {this.skuTableList.map(i => (
            <el-table-column
              prop={ i.prop }
              label={ i.label }
              align={ i.align || 'center' }
              v-slots={ i.slot }
            />
          ))}
        </el-table>
      }
    }
    return (
      <el-table
        data={ this.tableData }
        rowKey={ this.getRowKeys }
        expand-row-keys={this.expands}
        onSelectionChange={ this.selectionChange }
        onExpandChange={this.ExpandChange}
      >
        <el-table-column type="selection" width="55" />
        <el-table-column type="expand" v-slots={slot} />
        {this.tableList.map(item => (
          <el-table-column
            prop={ item.prop }
            label={ item.label }
            align={ item.align || 'center' }
            v-slots={ item.slot }
          />
        ))}
      </el-table>
    )
  }

  getRowKeys(row): number {
    return row.id
  }

  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return (
      this.type
        ? <CommodityDetail ref="commodity" />
        : <div class="mall">
          <Card title="商品查询" class="mall-card">
            <Search list={ this.searchList } class="mall-search" onSearch={ this.onSearch } />
          </Card>
          <Card title="商品信息">
            <div class="mall-search">
              <Button list={ this.buttonList } onClick={ this.bttonClick } />
              {this.tableDom()}
              <Pagination ref="pagination" total={ this.total } onSizeChange={this.getList} onCurrentChange={this.getList} />
            </div>
          </Card>
          {this.imgViewerDom()}
        </div>
    )
  }
  mounted(): void {
    this.getList()
  }
}
