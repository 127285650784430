import Tabs from "@/components/Tabs";
import { TabListRow } from "@/models/type";
import { Options, Vue } from "vue-class-component";
import SpaceInstitution from "./components/spaceInstitution";
import SpaceIntroduction from "./components/spaceIntroduction";
import SpaceLink from "./components/spaceLink";
import SpaceTeam from "./components/spaceTeam";

import './index.scss'

/**
 * @description 空间管理
 * @author Jinx
 * @date 2021-08-20 09:34:04
 * @export
 * @class Space
 * @extends {Vue}
 */
@Options({
  components: { SpaceIntroduction, SpaceTeam, SpaceInstitution, SpaceLink }
})
export default class Space extends Vue {

  private activeName = 'introduction'

  public tabList?: Array<TabListRow> = [
    { title: '空间介绍', activeName: 'introduction' },
    { title: '团队成员', activeName: 'team' },
    { title: '合作机构', activeName: 'institution' },
    { title: '友好连接', activeName: 'link' }
  ]

  private slots = {
    introduction: () => <SpaceIntroduction />,
    team: () => <SpaceTeam />,
    institution: () => <SpaceInstitution />,
    link: () => <SpaceLink />
  }


  activeChange(keyName: string): void {
    this.activeName = keyName
  }

  render(): JSX.Element {
    return (
      <div class="space">
        <div class="space-content">
          <Tabs
            tabList={ this.tabList as unknown[] }
            activeName={ this.activeName }
            onActivechange={ this.activeChange }
            v-slots={ this.slots }
          />
        </div>
      </div>
    )
  }
}
