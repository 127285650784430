import api from "@/api";
import Button from "@/components/Button";
import { SysCooperation } from "@/models/home";
import { ButtonListRow } from "@/models/type";
import { debounceClick, typeWatch } from "@/utils";
import { ElMessageBox, ElNotification } from "element-plus";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";

/**
 * 友好连接
 *  @type {*} */
const SpaceLink = defineComponent({

  setup(){

    const tableData = ref([])
    const title = computed(() => typeWatch(type.value))
    const type = ref('')
    const detailBool = ref(false)
    let linkForm: any = reactive({
      title: ''
    })

    const buttonList: ButtonListRow[] = [
      { title: '添加', icon: 'el-icon-plus', click: 'linkAdd' }
    ]

    function buttonClick(click: string): void {
      switch(click) {
        case 'linkAdd':
          linkAdd()
          break
        default:
      }
    }

    function linkAdd(): void {
      type.value = 'add'
      detailBool.value = true
    }

    function linkEdit(row: any): void {
      linkForm = Object.assign({}, row)
      type.value = 'edit'
      detailBool.value = true
    }

    function linkDelete(id: number): void {
      ElMessageBox.confirm('确定删除该友好连接？', '删除友好连接', {
        type: 'warning'
      }).then(() => {
        api.homeLinkDeleteCooperation([id]).then(() => {
          ElNotification({
            title: '成功',
            type: 'success',
            message: '删除友好连接成功'
          })
          getList()
        })
      }).catch(err => {
        return err
      })
    }

    function detailDom(): JSX.Element {
      const slots: any = {
        footer: (): JSX.Element => (
          <span class="dialog-footer">
            <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ detailClose } >取 消</button>
            <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(submit) }>确 定</button>
          </span>
        )
      }

      return (
        <el-dialog
          title={ `${ title.value }友好连接` }
          v-model={ detailBool.value }
          before-close={ detailClose }
          width="30%"
          v-slots={ slots }
        >
          <el-form
            ref="form"
            model={ linkForm }
            label-width="120px"
            class="el-form-tao"
          >
            <el-form-item label="名称" prop="title">
              <el-input v-model={ linkForm.title } clearable placeholder="请输入连接名称" />
            </el-form-item>
          </el-form>
        </el-dialog>
      )
    }

    function submit(): void {
      const form = Object.assign({}, linkForm)
      type.value === 'add'
        ? submitAdd(form)
        : submitEdit(form)
    }

    function submitAdd(form: SysCooperation): void {
      delete form.id
      api.homeLinkAddCooperation(form).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '添加友好连接成功'
        })
        detailClose()
        getList()
      })
    }

    function submitEdit(form: SysCooperation): void {
      api.homeLinkEditCooperation(form).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '编辑友好连接成功'
        })
        detailClose()
        getList()
      })
    }

    function detailClose(): void {
      detailBool.value = false
      type.value = ''
      linkForm.title = ''
    }

    function getList(): void {
      api.homeLinkGetCooperationList().then(res => {
        tableData.value = res.data
      })
    }

    onMounted(() => {
      getList()
    })

    return () => (
      <div class="space-link">
        <Button list={buttonList} onClick={buttonClick} />
        <div class="space-link-data">
          {tableData.value.map((item: any) => (
            <el-tag type="info" closable onClick={() => linkEdit(item)} onClose={ () => linkDelete(item.id)} >{ item.title }</el-tag>
          ))}
        </div>
        {detailBool.value && detailDom()}
      </div>
    )
  }
})

export default SpaceLink
