import { Options, Vue } from "vue-class-component";
import Button from "@/components/Button";
import { ButtonListRow, PaginationRow, TableRow } from "@/models/type";
import Pagination from "@/components/Pagination";
import api from "@/api";
import { SmsHomeImg } from "@/models/home";
import { debounceClick, getFile, typeWatch } from "@/utils";
import { ElMessageBox, ElNotification } from "element-plus";
import Switch from "@/components/Switch";
@Options({
  watch: {
    type(val?: string): void {
      this.title = typeWatch(val)
    },
    srcNum(num: number): void {
      if(num) {
        this.imgViewerShow()
      }
    },
    listSum(num: number): void {
      if(num) {
        this.getList()
      }
    }
  }
})
export default class FirstHome extends Vue {
  loading = false
  srcNum = 0
  listSum = 0
  total = 0
  title = ''
  type = ''
  list: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'homeAdd'}
  ]

  private detailBool = false
  private homeForm: SmsHomeImg = {
    title: '',
    type: 1,
    url: '',
    imgPath: ''
  }

  private formRules: any = {
    imgPath: [
      { required: true, message: '请上传对应文件' }
    ]
  }

  private url = ''
  private srcList: Array<string> = []

  private videoUrl = ''

  videoViewerShow = false // 视频显示

  tableData: Array<any> = []

  tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any): JSX.Element => <span>{ scope.$index + 1 }</span>
    } },
    { label: '名称', prop: 'title' },
    { label: '图片/视频', slot: {
      default: (scope: any): JSX.Element => scope.row.type === 2
        ? <video class="first-home-imgPath pointer" src={ scope.row.imgPath } onClick={() => this.videoShow(scope.row.imgPath)} />
        : <img class="first-home-imgPath pointer" src={ scope.row.imgPath } alt={ scope.row.title } onClick={() => this.imgShow(scope.row.imgPath)} />
    } },
    { label: '连接', prop: 'url' },
    { label: '属性', slot: {
      default: (scope: any): JSX.Element => <span>{ scope.row.type === 2 ? '视频' : '图片' }</span>
    } },
    { label: '状态', slot: {
      default: (scope: any): JSX.Element => <Switch
        switchBool={ scope.row.status }
        bindValue={{ open: 1, close: 0 }}
        onSwitch={this.onSwitch.bind(this, scope.row)}
      />
    } },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class="span-button-primary" onClick={ () => this.homeEdit(scope.row) }>编辑</span>
        <span class="span-button-danger" onClick={ () => this.homeDelete(scope.row.id, scope.$index) }>删除</span>
      </div>
    } },
  ]

  onSwitch(row: SmsHomeImg): void {
    if(row.status) {
      ElNotification({
        type: 'warning',
        message: '该信息已经是默认项, 必须保留一项'
      })
      this.listSum++
      return
    }
    api.homeImgSetStatus(row.id as number).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '已将该信息设置为默认展示项'
      })
      this.tableData.map(item => item.status = 1)
      this.listSum++
    })
  }

  /**
   * @description 图片展示
   * @author Jinx
   * @date 2021-08-19 14:28:34
   * @memberof FirstHome
   */
  imgShow(url: string): void {
    this.url = url
    this.srcList = this.tableData.filter(item => item.type === 1).map(item => item.imgPath) // 将类型为图片的给srcList
    this.srcNum++
  }

  /**
   * @description 视频展示
   * @author Jinx
   * @date 2021-08-19 14:51:41
   * @param {string} url
   * @memberof FirstHome
   */
  videoShow(url: string): void {
    this.videoUrl = url
    this.videoViewerShow = true
  }

  buttonClick(click: string): void {
    this[click]()
  }

  /**
   * @description 首页添加内容
   * @author Jinx
   * @date 2021-08-13 17:14:59
   * @memberof FirstHome
   */
  homeAdd(): void {
    this.type = 'add'
    this.detailBool = true
  }

  homeEdit(row: SmsHomeImg): void {
    this.homeForm = Object.assign({}, row)
    this.type = 'edit'
    this.detailBool = true
  }

  homeDelete(id: number, index: number): void {
    ElMessageBox.confirm('确定删除该信息？', '删除信息', {
      type: 'warning'
    }).then(() => {
      api.homeImgDeleteHomeImg([id]).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除成功'
        })
        this.tableData.splice(index, 1)
      })
    }).catch(err => {
      return err
    })
  }

  /**
   * @description 列表
   * @author Jinx
   * @date 2021-30-12 16:08:19
   * @returns {*}  {JSX.Element}
   * @memberof FirstHome
   */
  tableDom(): JSX.Element {
    return (
      <el-table
        ref="table"
        data={ this.tableData }
      >
        {this.tableList.map(item => (
          <el-table-column
          prop={ item.prop }
          label={ item.label }
          align={ item.align || 'center' }
          v-slots={ item.slot }
        />
        ))}
      </el-table>
    )
  }

  /**
   * @description 获取首页列表
   * @author Jinx
   * @date 2021-08-12 17:08:28
   * @memberof FirstHome
   */
  getList(): void {
    this.tableData = []
    const __pagination = this.$refs.pagination as any
    const { pageSize, pageNum } = __pagination.paginationObj as PaginationRow
    api.homeImgGetHomeImgList({pageSize, pageNum}).then(res => {
      this.total = res.data.total
      this.tableData = res.data.list
    })
  }

  detailClose(): void {
    this.detailBool = false
    this.type = ''
    this.homeForm = {
      title: '',
      type: 1,
      url: '',
      imgPath: ''
    }
  }

  /**
   * @description 详情dom
   * @author Jinx
   * @date 2021-08-19 11:16:43
   * @returns {*}  {JSX.Element}
   * @memberof FirstHome
   */
  detailDom(): JSX.Element {

    const slots: any = {
      footer: (): JSX.Element => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose } >取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
        </span>
      )
    }

    return (
      <el-dialog
        title={ `${ this.title }信息` }
        v-model={ this.detailBool }
        before-close={ this.detailClose }
        width="30%"
        v-slots={ slots }
      >
        <el-form
          ref="form"
          model={ this.homeForm }
          rules={ this.formRules }
          label-width="120px"
          class="el-form-tao"
        >
          <el-form-item label="名称" prop="title">
            <el-input v-model={ this.homeForm.title } clearable placeholder="请输入活动名称" />
          </el-form-item>
          <el-form-item prop="type">
            <el-radio-group v-model={ this.homeForm.type } onChange={ this.typeChange } >
              <el-radio label={ 1 }>图片</el-radio>
              <el-radio label={ 2 }>视频</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="imgPath">
            <div>
              { this.loading
                ? <span class="el-form-tao-btn" >上传中...</span>
                : <span class="el-form-tao-btn" onClick={ this.uploadFile }>{ this.homeForm.imgPath ? '重新上传' : '上传' }</span>}
              {this.homeForm.imgPath && <span class="el-form-tao-view" onClick={this.someView} >预览</span>}
            </div>
          </el-form-item>
          { this.homeForm.type === 1 && <el-form-item label="连接" prop="url">
            <el-input v-model={ this.homeForm.url } clearable placeholder="请输入连接地址" />
          </el-form-item>}
        </el-form>
      </el-dialog>
    )
  }

  /**
   * @description 不同类型的预览
   * @author Jinx
   * @date 2021-08-19 14:36:25
   * @memberof FirstHome
   */
  someView(): void {
    if( this.homeForm.type === 1 ) {
      const url = this.homeForm.imgPath as string
      this.url = url
      this.srcList = [url]
      this.imgViewerShow()
    } else {
      this.videoUrl = this.homeForm.imgPath as string
      this.videoViewerShow = true
    }
  }

  /**
   * @description 状态改变时的动作
   * @author Jinx
   * @date 2021-08-19 10:51:51
   * @memberof FirstHome
   */
  typeChange(): void {
    this.homeForm.imgPath = '' // 将文件去除
  }

  uploadFile(): void {
    getFile(files => {
      if(!files.length) {
        return
      }
      const formData = new FormData()
      formData.append('file', files[0])
      this.loading = true
      api.commonOssUpload(formData).then(res => {
        this.homeForm.imgPath = res.data
        ElNotification({
          title: '成功',
          type: 'success',
          message: `上传${this.homeForm.type === 2 ? '视频' : '图片'}文件成功`
        })
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }, 'HOME_FIRST', this.homeForm.type === 2 ? 'video/*' : 'image/*')
  }

  submit(): void {
    const __form = this.$refs.form as any
    __form.validate(v => {
      if (v) {
        const form = Object.assign({}, this.homeForm)
        this.type === 'add'
          ? this.submitAdd(form)
          : this.submitEdit(form)
      } else {
        return false
      }
    })
  }

  submitAdd(form: SmsHomeImg): void {
    delete form.id
    api.homeImgAddHomeImg(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '添加信息成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  submitEdit(form: SmsHomeImg): void {
    api.homeImgEditHomeImg(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '编辑信息成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  /**
   * @description 图片dom
   * @author Jinx
   * @date 2021-08-19 14:27:33
   * @returns {*}  {JSX.Element}
   * @memberof FirstHome
   */
  imgViewerDom(): JSX.Element {
    return <el-image
      ref="img"
      style="width: 0; height: 0; z-index: 54188;"
      src={ this.url }
      preview-src-list={ this.srcList }
    >
    </el-image>
  }

  /**
   * @description 图片展示
   * @author Jinx
   * @date 2021-08-19 14:27:43
   * @memberof FirstHome
   */
  imgViewerShow(): void {
    const __img =  this.$refs.img as any
    __img.showViewer = true
  }

  videoViewerDom(): JSX.Element {
    return (
      <el-dialog
        v-model={ this.videoViewerShow }
        append-to-body
      >
        <video src={ this.videoUrl } style="width: 100%" autoplay controls></video>
      </el-dialog>
    )
  }

  render(): JSX.Element {
    return (
      <div class="first-home">
        <Button list={ this.list } onClick={ this.buttonClick } />
        {this.tableDom()}
        <Pagination ref="pagination" total={ this.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
        {this.detailBool && this.detailDom()}
        {this.imgViewerDom()}
        {this.videoViewerShow && this.videoViewerDom()}
      </div>
    )
  }

  mounted(): void {
    this.getList()
  }

  /* upload(): void {
    const __table = this.$refs.table as any
    __table.doLayout()
  } */
}
